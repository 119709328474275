// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Backdrop_Backdrop__iVu8d {
  background-color: none;
  backdrop-filter: blur(0);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.3s ease-out;
}
.Backdrop_Backdrop__show__5RM-w {
  backdrop-filter: blur(0.5rem);
  background-color: rgba(94, 126, 79, 0.6235294118);
  z-index: 100;
}
.Backdrop_Backdrop__hidden__n9aW9 {
  background-color: transparent;
  backdrop-filter: blur(0);
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Backdrop/Backdrop.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,wBAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,6BAAA;AACJ;AAEI;EACE,6BAAA;EACA,iDAAA;EACA,YAAA;AAAN;AAGI;EACE,6BAAA;EACA,wBAAA;EACA,WAAA;AADN","sourcesContent":[".Backdrop {\n    background-color: none;\n    backdrop-filter: blur(0);\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n    transition: all 0.3s ease-out;\n    // touch-action: none;\n  \n    &__show {\n      backdrop-filter: blur(0.5rem);\n      background-color: #5e7e4f9f;\n      z-index: 100;\n    }\n  \n    &__hidden {\n      background-color: transparent;\n      backdrop-filter: blur(0);\n      z-index: -1;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": `Backdrop_Backdrop__iVu8d`,
	"Backdrop__show": `Backdrop_Backdrop__show__5RM-w`,
	"Backdrop__hidden": `Backdrop_Backdrop__hidden__n9aW9`
};
export default ___CSS_LOADER_EXPORT___;
