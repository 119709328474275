// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Create_PositionSubmission__AG3jS {
  width: 60rem;
}
.Create_PositionSubmission__TopInfo__UWyxT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 2rem 0;
}
.Create_PositionSubmission__TopInfo__Buttons__Z19Hd {
  display: flex;
  gap: 1rem;
}

.Create_Quill__Liv3J {
  background-color: rgb(248.85, 248.85, 248.85);
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Member/Volunteer/Create/Create.module.scss","webpack://./src/assets/sass/_variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,qBAAA;AAAJ;AAEI;EACE,aAAA;EACA,SAAA;AAAN;;AAKA;EACE,6CCIiB;EDHjB,mBAAA;AAFF","sourcesContent":["@import \"../../../../assets/sass/variables\";\n\n.PositionSubmission {\n  width: 60rem;\n  &__TopInfo {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 1rem 0 2rem 0;\n\n    &__Buttons {\n      display: flex;\n      gap: 1rem;\n    }\n  }\n}\n\n.Quill {\n  background-color: $color-grey-light;\n  margin-bottom: 2rem;\n}\n","// COLORS\n$color-primary: #0f2167;\n$color-primary-light: lighten($color-primary, 65%);\n$color-primary-lighter: lighten($color-primary, 73%);\n$color-primary-dark: darken($color-primary, 10%);\n$color-primary-darker: darken($color-primary, 20%);\n\n$color-secondary: #1a0f66;\n$color-secondary-light: lighten($color-secondary, 25%);\n$color-secondary-lighter: lighten($color-secondary, 73%);\n\n$color-tertiary: #0f5766;\n$color-tertiary-light: lighten($color-tertiary, 25%);\n$color-tertiary-lighter: lighten($color-tertiary, 70%);\n$color-tertiary-dark: darken($color-tertiary, 9%);\n$color-tertiary-darker: darken($color-tertiary, 50%);\n\n// $color-quaternary: #0F2167;\n\n$color-black: #111;\n\n$color-grey: #b4b4b4;\n$color-grey-light: lighten($color-grey,27%);\n\n$color-white: #eee;\n\n// FONT\n$default-font-size: 1.6rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PositionSubmission": `Create_PositionSubmission__AG3jS`,
	"PositionSubmission__TopInfo": `Create_PositionSubmission__TopInfo__UWyxT`,
	"PositionSubmission__TopInfo__Buttons": `Create_PositionSubmission__TopInfo__Buttons__Z19Hd`,
	"Quill": `Create_Quill__Liv3J`
};
export default ___CSS_LOADER_EXPORT___;
