const Member = () => {
  // const signOut = async (e) => {
  //   e.preventDefault();
  //   console.log("sign out");

  //   server.get("/logout").then((res) => {
  //     setIsLoggedIn(false);
  //     setAuthUser(null);
  //   });
  // };

  return (
    <>
      <div>Member</div>
    </>
  );
};

export default Member;
